import * as React from "react";
import { berechneSteuerlast, berechneRewards } from "./functionen";

// const content = {
//   warnung: "Ich übernehme keine Haftung"
//   zweite-warnung: "Ich übernehme keine Haftung"
// }

// Zugriff content.warnung oder content['zweite-warnung']

//konstante
const REWARD_REDUCTION = 1.68; //Reduktion der Rewards alle zwei Wochen um 1,68%

export const Form = () => {
  const [investFiat, setInvestFiat] = React.useState(0);
  const [kurs, setKurs] = React.useState(0);
  const [aPY, setAPY] = React.useState(0);

  const [dauerInMonat, setDauerInMonat] = React.useState(1);
  const [durchkurs, setDurchKurs] = React.useState(0); //Durchschnitlicher Kurs nach X  {dauerInMonaten}
  const [verkaufskurs, setVerkaufskurs] = React.useState(0); //Preis beim Verkauf
  const [bruttoLohn, setbruttoLohn] = React.useState(1);

  const aprMonat = (((aPY / 100 + 1) ** (1 / 12) - 1) * 100).toFixed(4);
  const aprWoche = (((aPY / 100 + 1) ** (1 / 52) - 1) * 100).toFixed(4);
  const aprZweiWochen = (((aPY / 100 + 1) ** (1 / 26) - 1) * 100).toFixed(4);

  const stackedCoins = (investFiat / kurs).toFixed(2);

  let rewardInCoin = 0.0;
  let rewardInFiat = 0.0;
  let steurMehraufwendung = 0.0;
  let gewinnNachSteuer = 0.0;

  let kapitalInCoinOhneverkauf = 0;
  let kapitalInFiatOhneVerkauf = 0;
  let kapitalInFiatOhne = 0;

  const onChangeKurs = (e) => setKurs(parseFloat(e.currentTarget.value));
  const onChangeDurchKurs = (e) => setDurchKurs(parseFloat(e.currentTarget.value));
  const onChangeInvest = (e) => setInvestFiat(parseFloat(e.currentTarget.value));
  const onChangeApy = (e) => setAPY(parseFloat(e.currentTarget.value));
  const onChangeDauerInMonaten = (e) => setDauerInMonat(parseInt(e.currentTarget.value));
  const onChangeBruttoLohn = (e) => setbruttoLohn(parseFloat(e.currentTarget.value));
  const onChangeVerkaufskurs = (e) => setVerkaufskurs(parseFloat(e.currentTarget.value));

  //auto expand textarea

  rewardInCoin = berechneRewards(
    parseFloat(dauerInMonat),
    parseFloat(aprZweiWochen),
    parseFloat(REWARD_REDUCTION),
    stackedCoins
  ).toFixed(2);

  rewardInFiat = (rewardInCoin * durchkurs).toFixed(2);

  kapitalInCoinOhneverkauf = (parseFloat(rewardInCoin) + parseFloat(stackedCoins)).toFixed(2);
  kapitalInFiatOhneVerkauf = (kapitalInCoinOhneverkauf * durchkurs - steurMehraufwendung).toFixed(2);
  kapitalInFiatOhne = (stackedCoins * durchkurs).toFixed(2);

  let bruttoMitStaking = parseFloat(bruttoLohn) + parseFloat(rewardInFiat);
  let steuerlastMitStaking = berechneSteuerlast(bruttoMitStaking).toFixed(2);
  let steuerlastOhneStaking = berechneSteuerlast(parseFloat(bruttoLohn));

  steurMehraufwendung = steuerlastMitStaking - steuerlastOhneStaking;

  gewinnNachSteuer = (rewardInFiat - steurMehraufwendung).toFixed(2);

  //Berechnung der Steuer nach Verkauf
  //
  //GewinnCoins = Gewinn - Anschafungspreis
  //steuer(GEwinCoins) -steuer(Lohn) = Differenz

  // Gewinn = Kursgewinn Kapital/Coins + GewinRewards + Rewards
  //Gewinn = (Coins*verkaufskurs - Investbetrag) + (Rewardsmenge*verkfspreis - Rewardsmenge*durchpreis) + (Rewardsmenge * durchpreis)
  let einkommenVerkauf = 0;
  if (parseFloat(verkaufskurs) <= parseFloat(durchkurs)) {
    einkommenVerkauf =
      parseFloat(stackedCoins) * verkaufskurs - parseFloat(investFiat) + parseFloat(rewardInCoin) * durchkurs;
    //console.log(parseFloat(verkaufskurs) <= parseFloat(durchkurs), "verkurs kleiner durch",durchkurs);
  } else {
    einkommenVerkauf =
      parseFloat(stackedCoins) * verkaufskurs - parseFloat(investFiat) + parseFloat(rewardInCoin) * verkaufskurs;
    //console.log(parseFloat(verkaufskurs) <= parseFloat(durchkurs),"verkurs Größer durch", durchkurs);
  }

  let einkommenGesamt = parseFloat(einkommenVerkauf) + parseFloat(bruttoLohn);
  let steuerAufEinkommenGesamt = berechneSteuerlast(einkommenGesamt);
  let steuerUnterschied = parseFloat(steuerAufEinkommenGesamt) - berechneSteuerlast(bruttoLohn);

  //console.log("einkommenVerkauf: ", einkommenVerkauf);
  //console.log("einkommenGesamt: ", einkommenGesamt);
  //console.log("steuerAufEinkommenGEsammt: ", steuerAufEinkommenGesamt);
  //console.log("steuerUnterschied: ", steuerUnterschied);

  //depovergleich Verkauf von allen mit Verkaufspreis vs Hoddl unter einem Jahr
  // berechne Hodl steuer (Verkaufawert-Kaufvwert)-Gesamtsteuer
  //Anzahl der Coins ohne Staking(505)*Verkaufswert(3,2)= Verkaufswert
  //steuer(Brotolohn+(Verkaufwert-Kapital))-steuer(Brutolohn) = steuer ohne Staking
  let steuerOhneStakingbeiVerkauf =
    berechneSteuerlast(
      parseFloat(bruttoLohn) + (parseFloat(stackedCoins) * parseFloat(verkaufskurs) - parseFloat(investFiat))
    ) - berechneSteuerlast(parseFloat(bruttoLohn));

  let depotWertOhneStakingbeiVerkauf = parseFloat(stackedCoins) * parseFloat(verkaufskurs);

  // berechne Steuer auf Staking mit verkaufswert
  //(Depowert(898,00) * Verkaufspreis(3,2)) - Steuer (654,02) = 2219 (Wert nach dem Staking und Verkauf und bezahlter steuer.)

  let depotWertOhneStakingbeiVerkaufAbzSteuer = depotWertOhneStakingbeiVerkauf - steuerOhneStakingbeiVerkauf;

  let steuerMitStakingbeiVerkauf = steuerUnterschied; //parseFloat(steuerNachteilBeimVerkauf);//

  //Diferenz aus den Preisen ziehen
  let depotWertMitStakingbeiVerkauf = kapitalInCoinOhneverkauf * parseFloat(verkaufskurs);

  let depotWertMitStakingbeiVerkaufAbzSteuer = depotWertMitStakingbeiVerkauf - steuerMitStakingbeiVerkauf;

  //Differenz
  let steuerDifferenznachVerkauf = depotWertMitStakingbeiVerkaufAbzSteuer - depotWertOhneStakingbeiVerkaufAbzSteuer;

  //flexboxzombies
  //scrimba
  //pritier.IO
  return (
    <div>
      <div class="item1">
        <form class="form-style-7">
          <ul>
            <li>
              <label> Kapital</label>
              <input
                type="number"
                value={investFiat}
                onChange={onChangeInvest}
                placeholder="0.2"
                step="0.01"
                maxlength="100"
              />
              <span>Das investierte Kapital in Fiat (€) </span>
            </li>
            <li>
              <label for="Kaufpreis"> Kaufpreis </label>
              <input type="number" value={kurs} onChange={onChangeKurs} step="0.01" maxlength="100" />
              <span>Kaufpreis zum Zeitpunkt des Wechselns</span>
            </li>
            <li>
              <label>APY in %</label>
              <input type="number" value={aPY} onChange={onChangeApy} step="0.1" />
              <span>
                ({aprMonat} % / mon) oder ({aprWoche} % / week)
              </span>
            </li>
            <li>
              <label>Zeitspanne</label>
              <input type="range" value={dauerInMonat} onChange={onChangeDauerInMonaten} step="1" min="1" max="12" />
              <span>{dauerInMonat} Monate</span>
            </li>
            <li>
              <label> Durschnittspreis der Rewards </label>
              <input type="number" value={durchkurs} onChange={onChangeDurchKurs} step="0.01" />
              <span>Durchschnittlicher Preis/Coin während der Haltedauer</span>
            </li>
            <li>
              <label> Verkaufspreis in €</label>
              <input type="number" value={verkaufskurs} onChange={onChangeVerkaufskurs} step="0.01" />
              <span>Preis zum Verkaufszeitpunkt</span>
            </li>
            <li>
              <label> Bruttolohn in € </label>
              <input type="number" value={bruttoLohn} onChange={onChangeBruttoLohn} step="0.01" />
              <span>Das zu versteuernde Einkommen ohne Staking</span>
            </li>
          </ul>
        </form>

        <form class="form-style-7">
          <ul>
            <li>
              <label>Coins gestaked: {stackedCoins}</label>
              <h2></h2>
              <span>Erhaltene Rewards</span>
              <h2>{rewardInCoin} Coins</h2>
              <h2>{rewardInFiat} €</h2>
              <span>Gewinn abzgl. Steuer für Rewards</span>
              <h2>{gewinnNachSteuer} €</h2>
            </li>
            <li>
              <label>Steuer </label>
              <h2></h2>
              <span>Steuer für Bruttolohn </span>
              <h2>{steuerlastOhneStaking.toFixed(2)} €</h2>
              <span>Steuer für Lohn und Rewards </span>
              <h2>{steuerlastMitStaking} €</h2>

              <span>Steuer für Rewards </span>
              <h2>{steurMehraufwendung.toFixed(2)} €</h2>

              <span>Steuer nach Verkauf von Rewards und Coins </span>

              <h2>{steuerUnterschied.toFixed(2)} €</h2>

              <span>Gesamtsteuer auf Lohn, Rewards und Verkauf</span>
              <h2>{steuerAufEinkommenGesamt.toFixed(2)} €</h2>
            </li>
          </ul>
        </form>

        <form class="form-style-7">
          <ul>
            <li>
              <label>Depot mit Staking </label>
              <h2></h2>
              <span>Anzahl der Coins </span>
              <h2>{kapitalInCoinOhneverkauf} Coins</h2>
              <span>Wert </span>
              <h2>{depotWertMitStakingbeiVerkauf.toFixed(2)} €</h2>
              <span>anfallende Steuer </span>
              <h2>{steuerMitStakingbeiVerkauf.toFixed(2)} €</h2>
              <span>Wert abzgl. Steuer </span>
              <h2>{depotWertMitStakingbeiVerkaufAbzSteuer.toFixed(2)} €</h2>
            </li>

            <li>
              <label>Depot ohne Staking </label>
              <h2></h2>
              <span>Anzahl der Coins </span>
              <h2>{stackedCoins} Coins</h2>
              <span>Wert </span>
              <h2>{depotWertOhneStakingbeiVerkauf.toFixed(2)} €</h2>
              <span>anfallende Steuer </span>
              <h2>{steuerOhneStakingbeiVerkauf.toFixed(2)} €</h2>
              <span>Wert abzgl. Steuer </span>
              <h2>{depotWertOhneStakingbeiVerkaufAbzSteuer.toFixed(2)} €</h2>
            </li>
          </ul>
        </form>

        <form class="form-style-7">
          <ul>
            <li>
              <label>Vorteil/Nachteil durch Staking </label>
              <h2></h2>
              <span>Differenz nach Verkauf und Steuer </span>
              <h2>{(kapitalInCoinOhneverkauf - stackedCoins).toFixed(2)} Coins</h2>
              <h2>{steuerDifferenznachVerkauf.toFixed(2)} €</h2>
            </li>
            <li>
              <label class="info">Infobox</label>
              <h2></h2>
              <span>Reduktion der Blockrewards wird berücksichtigt</span>
              <h2></h2>
              <span>
                Steuern werden nach dem progressiven Einkommenssteuersatz, nach der offiziellen Formel des
                Bundesfinanzministeriums berechnet
              </span>
              <h2></h2>
              <span>Steuern auf die Einlage werden berücksichtigt</span>
              <h2></h2>
              <span>Berechnungsgrundlage für das Jahr 2021</span>
              <h2></h2>
            </li>
            <li>
              <label class="warn">! Warnhinweis ! </label>
              <h2>Betreiber übernimmt keine Haftung bei Abweichung/Rechenfehler</h2>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
};

import logo from "./logo.png";
import "./App.css";
import { Form } from "./form";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a
          className="App-link"
          //href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Reward- und Steuerrechner für gestakte (DEFI) Coins
        </a>
        <Form></Form>

      </header>
    </div>
  );
}

export default App;

//Kapital, Kaufpreis, APY in%  Zeitspaanne, CoinPreis nach X Monaten
//Durchschnittlicher PReis-Coin  + Gehalt Brutto
export function berechneSteuerlast(Brutto) {
  let BruttoTMP = parseFloat(Brutto);

  if (BruttoTMP <= 9744) {
    return 0;
  }
  if (9744 < BruttoTMP && BruttoTMP < 14753) {
    let yTMP = (BruttoTMP - 9744) / 10000;
    return (9995.21 * yTMP + 1400) * yTMP;
  }

  if (14753 < BruttoTMP && BruttoTMP < 57918) {
    let zTMP = parseFloat((BruttoTMP - 14753) / 10000);
    let tmptmp = (208.85 * zTMP + 2397) * zTMP + 950.9;
    return tmptmp;
  }

  if (57918 < BruttoTMP && BruttoTMP < 274612) {
    return 0.42 * BruttoTMP - 9136.63;
  }

  if (BruttoTMP > 274612) {
    return 0.45 * BruttoTMP - 17374.99;
  }

  return 0;
}

export function berechneRewards(Dauer, APR, Reduktion, Coins) {
  let tmp = 1;
  let aprReduktiert = parseFloat(APR);
  let tmpCoins = Coins;
  let tmpReward = 0;

  while (tmp <= Dauer * 30.41) {
    if (!(tmp % 14)) {
      aprReduktiert = aprReduktiert * ((100 - Reduktion) / 100);
      //Reward = Kapital*zweiwochenApy
      tmpCoins = parseFloat(tmpCoins) + parseFloat(tmpReward);
      tmpReward = parseFloat(tmpCoins) * parseFloat(aprReduktiert / 100);

      //console.log("Woche: " +tmp +" Monate: " + Dauer + " APRed: " + aprReduktiert + " TMPRew: " +tmpReward + " TMPCoins: " + tmpCoins)
    }
    tmp++;
  }
  //console.log("Dauer: " + Dauer + " Coins: " +tmpCoins +"DFI")
  //console.log("APR: " + APR)
  //console.log("_______________________")
  //console.log("TMPCoins: " +tmpCoins)
  return parseFloat(tmpCoins - Coins);
}
